<template>
  <v-container fluid ma-0 pa-0>
    <div class="purple-gradient fill-height">
      <Navbar />
    </div>

    <v-container class="body-container">
      <v-container class="pb-0" fluid>
        <v-row>
          <v-col>
            <Button
              text="Volver"
              outlined
              pill
              :height="44"
              prepend-icon="fa-caret-left"
              class="my-10"
              aria-label="Volver a lista de cuentas"
              url="AdminAccounts"
            />
          </v-col>
        </v-row>
        <div
          class="d-flex mb-4"
          :class="`d-flex ${
            $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
              ? 'flex-column-reverse'
              : 'flex-row justify-space-between'
          } `"
        >
          <div
            :class="`d-flex ${
              $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                ? 'flex-column'
                : 'flex-row'
            } `"
          >
            <div
              :class="`d-flex ${
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                  ? 'justify-center mb-6 mt-6'
                  : 'mr-8'
              } `"
            >
              <v-avatar size="120">
                <img
                  src="https://cdn.vuetifyjs.com/images/john.jpg"
                  alt="John"
                />
              </v-avatar>
            </div>
            <div
              :class="`d-flex ${
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                  ? 'justify-center mb-6 mt-6 flex-column'
                  : 'flex-column'
              } `"
            >
              <div>
                <div class="text-h4 font-weight-black mb-2">
                  {{ dataList[0].text }} - {{ dataList[1].text }}
                </div>
              </div>
              <div
                :class="`d-flex ${
                  $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                    ? 'flex-column'
                    : 'flex-row'
                } `"
              >
                <div class="text-h6 mr-4">
                  Usuario Zaturna desde: Febrero 2021
                </div>
                <div class="text-h6 mr-4">
                  {{ dataList[5].text }}, Chihuahua
                </div>
                <div class="text-h6 mr-4">
                  {{ starRank }}({{ reviewNumber }} reseñas)
                </div>
              </div>
            </div>
          </div>
          <div
            :class="`d-flex ${
              $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                ? 'justify-center'
                : 'justify-end'
            } `"
          >
            <div
              class="d-flex align-center align-sm-and-up-end flex-row mb-2 ml-sm-10"
            >
              <h6 class="mt-4 mr-2">Estatus:</h6>
              <div class="mt-4 mr-2">Desactivado</div>

              <v-switch
                v-model="accStatusActivated"
                :value="accStatusActivated === 'active' ? false : true"
                :color="
                  getColor(accStatusActivated) === 'active'
                    ? 'error'
                    : 'success'
                "
                :disabled="accStatusActivated ? false : true"
                hide-details
                @change="handleSwitchChange"
              ></v-switch>
              <div class="mt-4 mr-2">Activado</div>
            </div>
            <ModalAccActivation
              :open-dialog="openDialog"
              :status-text="getAccStatus(accStatusActivated)"
              :legal-name="dataList[0].text"
              :user-name="dataList[1].text"
              :user-email="dataList[2].text"
              :user-type="userType"
              @closeModalCancel="handleDialogCancel"
              @closeModalAccept="handleDialogClose"
            />
          </div>
        </div>

        <v-container v-if="!accStatusActivated" class="deAccount">
          <v-container class="d-flex flex-column flex-md-row">
            <h2>Cuenta desactivada</h2>
            <div class="ml-5 mt-2">{{ deactivatedDate }}</div>
          </v-container>
          <v-container class="d-flex flex-column flex-md-row">
            <div class="pr-md-16 mr-md-16">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Augue
              nisl mauris, tincidunt nulla ipsum ultricies venenatis.
              Pellentesque leo nunc ac facilisi eu vel feugiat vitae. Nisl quis
              amet tempus auctor fermentum, urna auctor mattis. Tempus vitae
              venenatis, pellentesque suspendisse.
            </div>
            <Button
              text="Activar cuenta"
              class="ml-md-16 mt-4 mt-md-0"
              aria-label="Activar cuenta"
              @event="handleActivateAcc()"
            />
          </v-container>
        </v-container>
        <v-container>
          <v-row class="d-flex justify-space-between">
            <v-col>
              <div class="text-h5 font-weight-black">Información general</div>
            </v-col>
          </v-row>
          <v-row class="mb-8">
            <v-col
              v-for="(data, index) in dataList"
              :key="index"
              class="d-flex flex-column"
              cols="12"
              xs="12"
              sm="12"
              md="5"
              lg="5"
              xl="5"
            >
              <div class="text-h6 font-weight-black">{{ data.title }}</div>
              <div class="text-h6">{{ data.text }}</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="text-h5 font-weight-black">Descripción</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="text-h6">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Augue
                nisl mauris, tincidunt nulla ipsum ultricies venenatis.
                Pellentesque leo nunc ac facilisi eu vel feugiat vitae. Nisl
                quis amet tempus auctor fermentum, urna auctor mattis. Tempus
                vitae venenatis, pellentesque suspendisse. Sagittis nulla leo
                erat massa arcu arcu faucibus sed. Ante hac mauris est sit duis
                volutpat dolor. Et ut quam quis vel augue in ac ac. Suspendisse
                viverra massa tempor augue integer tortor nisi. Diam sit viverra
                arcu sagittis neque nullam. Est felis viverra elementum tortor
                tortor fermentum amet, lectus aliquet. Praesent hendrerit sed
                amet tortor. Tempus id ultrices commodo tincidunt diam tellus.
                Diam suspendisse lacus sed molestie.
              </div>
            </v-col>
            <v-col cols="12">
              <h2>Reservaciones</h2>
              <!-- CHANGE TO RESERVATIONS WHEN VIEW IS COMPLETE -->
              <Pending-requests :request-list="filterResult" />
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-container>

    <Footer class="mt-12" />
  </v-container>
</template>

<script>
import Navbar from "@/components/Admin/Shared/Navbar.vue";
import Button from "@/components/Shared/Button.vue";
import ModalAccActivation from "@/components/Admin/Accounts/ModalAccActivation.vue";
import PendingRequests from "@/components/Admin/Homepage/PendingRequests.vue";
import Footer from "@/components/Shared/Footer.vue";

export default {
  name: "AccountsDetails",
  components: {
    Navbar,
    Button,
    PendingRequests,
    ModalAccActivation,
    Footer,
  },
  data: () => ({
    imageUrl: "https://cdn.vuetifyjs.com/images/john.jpg",
    dataList: [
      { title: "Nombre legal", text: "María Pérez Carrizales" },
      { title: "Nombre de preferencias", text: "Mary" },
      { title: "Correo", text: "correo@gmail.com" },
      { title: "Teléfono", text: "6141338761" },
      { title: "Dirección", text: "Av. Valle Escondido 5500-10" },
      { title: "Ciudad", text: "Chihuahua" },
      { title: "Código postal", text: "31115" },
    ],

    starRank: 4.9,
    reviewNumber: 150,
    deactivatedDate: "11/02/2021",
    userType: "Cliente",

    //Remove when reservations is done
    requestList: [
      {
        id: "1",
        name: "Nombre1",
        pendingBusiness: "Banquetes CUU",
        phoneNumber: "123 456 789 1011",
        email: "invitado@invitado.com",
        date: 1639636528000, //2021-dec-15
      },
      {
        id: "2",
        name: "Nombre2",
        pendingBusiness: "Empresa",
        phoneNumber: "123 456 789 1011",
        email: "invitado@invitado.com",
        date: 1633066528000, //2021-oct-30
      },
      {
        id: "3",
        name: "Nombre3",
        pendingBusiness: "Empresa",
        phoneNumber: "123 456 789 1011",
        email: "invitado@invitado.com",
        date: 1628919328000, //2021-aug-13
      },
      {
        id: "4",
        name: "Nombre4",
        pendingBusiness: "Banquetes CUU",
        phoneNumber: "123 456 789 1011",
        email: "invitado@invitado.com",
        date: 1638340528000, //2021-nov-30
      },
    ],

    accStatusActivated: true,
    accStatusText: "",
    accIsClientText: "",
    openDialog: false,
  }),
  computed: {
    filterResult() {
      return this.requestList.slice(this.requestList.length - 3);
    },
  },

  methods: {
    handleDialogClose() {
      this.openDialog = false;
    },
    handleSwitchChange() {
      this.openDialog = !this.openDialog;
    },
    handleDialogCancel() {
      this.openDialog = false;
      this.accStatusActivated = !this.accStatusActivated;
    },
    handleActivateAcc() {
      this.openDialog = true;
      this.accStatusActivated = !this.accStatusActivated;
    },
    getColor(status) {
      if (status === true) {
        return "success";
      } else if (status === false) {
        return "error";
      }
    },
    getText(status) {
      if (status === true) {
        return "Activada";
      } else if (status === false) {
        return "Desactivada";
      }
    },
    getAccStatus(status) {
      if (!status) {
        return (this.accStatusText = "Desactivar");
      } else if (status) {
        return (this.accStatusText = "Activar");
      }
    },
  },
};
</script>

<style scoped>
.purple-gradient {
  background-color: rgba(98, 37, 130, 255);
}
.deAccount {
  background-color: #6225821a;
}
</style>
