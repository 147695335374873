<template>
  <v-container>
    <v-data-table
      class="text-body-1"
      :headers="headers"
      :search="search"
      :filter-result="filterResult"
      :items="requestList"
      item-key="id"
      :single-select="singleSelect"
      :hide-default-footer="true"
      disable-pagination
      :header-props="{
        'sort-by-text': 'ordenar por',
      }"
    >
      <!-- <template v-slot:[`item.response`]="{ item }">
        <Button
          text="Responder"
          class="my-2"
          aria-label="Responder"
          url="AdminRequests"
          :url-params="{ id: item.event }"
        />
      </template> -->

      <template v-slot:[`item.response`]>
        <Button
          text="Responder"
          class="my-2"
          aria-label="Responder"
          url="AdminRequests"
        />
      </template>

      <template v-slot:[`item.date`]="{ item }">
        <v-chip color="transparent" class="tableFontChange">
          {{ dateFormater(item.date) }}
        </v-chip>
      </template>
      <template v-slot:no-data> No hay eventos registrados. </template>
    </v-data-table>
  </v-container>
</template>

<script>
import Button from "@/components/Shared/Button.vue";
import moment from "moment";

export default {
  name: "PendingRequests",
  components: {
    Button,
  },
  props: {
    filterResult: {
      type: [String, Array],
      default: "",
    },
    requestList: {
      type: [String, Array],
      default: "",
    },
  },
  data() {
    return {
      singleSelect: null,
      selected: [],
      search: "",
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "name",
          class: "text-h5 black--text font-weight-bold",
        },
        {
          text: "Empresa",
          value: "pendingBusiness",
          class: "text-h5 black--text font-weight-bold",
        },
        {
          text: "Fecha",
          value: "date",
          class: "text-h5 black--text font-weight-bold",
        },
        {
          text: "Telefono",
          value: "phoneNumber",
          class: "text-h5 black--text font-weight-bold",
        },
        {
          text: "Correo",
          value: "email",
          class: "text-h5 black--text font-weight-bold",
        },
        {
          text: " ",
          value: "response",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    dateFormater(unixTime) {
      return moment(unixTime).format("DD/MM/yyyy");
    },
  },
};
</script>

<style scoped>
.v-data-table >>> td,
.tableFontChange {
  font-size: 18px !important;
}
</style>
