<template>
  <v-container>
    <v-dialog v-model="openDialog" persistent max-width="500px">
      <v-card class="px-5 px-sm-12 py-5">
        <v-row justify="center">
          <v-card-title class="text-h5 font-weight-bold mb-5">
            {{ statusText }} cuenta
          </v-card-title>

          <v-card-text class="pb-0 subtitle-1">
            {{ legalName }} - {{ userName }}
          </v-card-text>
          <v-card-text class="subtitle-1 pb-0">
            <v-container class="py-0 pl-0">
              <v-container class="d-flex flex-column flex-sm-row pa-0">
                <v-container class="pl-0">
                  <b>Correo electrónico</b>
                </v-container>
                <v-container class="pl-0">
                  {{ userEmail }}
                </v-container>
              </v-container>
            </v-container>
            <v-container class="py-0 pl-0">
              <v-container class="d-flex flex-column flex-sm-row pa-0">
                <v-container class="pl-0">
                  <b>Tipo de usuario</b>
                </v-container>
                <v-container class="pl-0">
                  {{ userType }}
                </v-container>
              </v-container>
            </v-container>
          </v-card-text>
          <v-container
            v-if="statusText == 'Desactivar'"
            class="pl-3 subtitle-1"
          >
            <b>Motivo de cambio de estatus</b>
            <v-textarea
              outlined
              row-height="2"
              dense
              rounded
              placeholder="Comentarios"
              color="primary"
              class="mt-3"
              :rules="optionalTextAreaRules"
            ></v-textarea>
          </v-container>
          <v-card-actions>
            <v-container class="d-flex flex-column pa-0 align-center">
              <Button
                :text="`${statusText} cuenta`"
                :aria-label="`${statusText} cuenta`"
                @event="closeModalAccept()"
              />
              <v-btn
                text
                class="mt-4 justify-center"
                width="250px"
                @click="closeModalCancel"
                ><u>Cancelar</u></v-btn
              >
            </v-container>
          </v-card-actions>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Button from "@/components/Shared/Button.vue";
import { optionalTextAreaRules } from "@/Utils/rules.js";

export default {
  name: "ModalAccActivation",
  components: {
    Button,
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    statusText: {
      type: String,
      default: "",
    },
    legalName: {
      type: String,
      default: "",
    },
    userName: {
      type: String,
      default: "",
    },
    userEmail: {
      type: String,
      default: "",
    },
    userType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialog: true,
      optionalTextAreaRules: optionalTextAreaRules,
    };
  },
  methods: {
    closeModalCancel() {
      this.$emit("closeModalCancel");
    },
    closeModalAccept() {
      this.$emit("closeModalAccept");
    },
  },
};
</script>

<style scoped>
.v-data-table td {
  border-bottom: none !important;
}

tr:hover {
  background-color: transparent !important;
}

.v-data-table tr {
  vertical-align: 0;
}
.v-input--selection-controls {
  margin-top: 0;
  padding: 0;
}
</style>
